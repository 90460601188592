import React from 'react'
import { Page404 } from '@klickmarketing/react-components'
import { graphql } from 'gatsby'
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next'

import Layout from '../components/Layout/Layout'

const NotFoundPage = () => {
  const { t } = useI18next()
  return (
    <Layout
      path="/404"
      seoProps={{
        title: t('Page Not Found', { ns: 'common' }),
      }}
    >
      <Page404
        bodyTextOverride={
          <Trans ns="common">
            There’s nothing different here. <br />
            Get back to something <Link to="/">different</Link>.
          </Trans>
        }
      />
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
